// src/components/form.js

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const schema = Joi.object({
  name: Joi.string().min(2).max(80).required(),
  email: Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: {},
    })
    .required(),
  message: Joi.string().required(),
  phone: Joi.string()
    .min(9)
    .max(10)
    .pattern(/^[0-9]+$/, 'numbers')
    .required(),
});

export default function Form() {
  const [submitted, setSubmitted] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });
  const onSubmit = (data) => {
    fetch(`/api/processForm`, {
      method: `POST`,
      body: JSON.stringify(data),
      headers: {
        'content-type': `application/json`,
      },
    })
      .then((res) => res.json())
      .then((body) => {
        body.message === 'OK' && setSubmitted(true);
      })
      .catch(() => {
        setSubmitFailed(true);
      });
  };

  const showSuccess = (
    <>
      <h3>Tack för ditt meddelande!</h3>
      <p>Du hör snart från oss.</p>
    </>
  );

  const showFail = (
    <>
      <h3>Ojdå, ute på djupt vatten!</h3>
      <p>Något gick tyvärr snett under inskick. Var god maila oss i stället. Tack!</p>
    </>
  );

  const showResponse = submitted ? showSuccess : showFail;

  const showForm = (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <label className="block font-bold" htmlFor="name">
            Namn {errors.name && <span className="text-red-400 p-4 pl-0 animate-pulse">måste fyllas i:</span>}
            <input
              type="text"
              placeholder="Ditt namn"
              autoComplete="name"
              disabled={isSubmitting}
              className="mt-2 mb-8 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black bg-gray-100"
              {...register('name')}
            />
          </label>
          <label className="block font-bold" htmlFor="email">
            E-postadress {errors.email && <span className="text-red-400 p-4 pl-0 animate-pulse">verkar vara fel:</span>}
            <input
              type="email"
              placeholder="Din e-postadress"
              autoComplete="email"
              disabled={isSubmitting}
              className="mt-2 mb-8 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black bg-gray-100"
              {...register('email')}
            />
          </label>
          <label className="block font-bold" htmlFor="phone">
            Telefonnummer{' '}
            {errors.phone && <span className="text-red-400 p-4 pl-0 animate-pulse">verkar vara fel:</span>}
            <input
              type="tel"
              placeholder="Ditt telefonnummer"
              autoComplete="phone"
              pattern="\d*"
              disabled={isSubmitting}
              className="mt-2 mb-8 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black bg-gray-100"
              {...register('phone')}
            />
          </label>
          <label className="block font-bold" htmlFor="message">
            Meddelande {errors.message && <span className="text-red-400 p-4 pl-0 animate-pulse">måste fyllas i:</span>}
            <textarea
              className="mt-2 mb-8  block px-0 w-full border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black bg-gray-100;"
              disabled={isSubmitting}
              placeholder="Vad kan vi hjälpa dig med?"
              rows="5"
              {...register('message')}
            />
          </label>
          <button
            type="submit"
            disabled={isSubmitting}
            className="disabled:opacity-30 disabled:pointer-events-none mt-2 px-6 py-4 leading-none text-white bg-greenblue border-2 border-greenblue rounded transition duration-500 ease-in-out hover:text-greenblue hover:bg-white"
          >
            Skicka meddelande
          </button>
        </fieldset>
      </form>
    </>
  );

  return submitted || submitFailed ? showResponse : showForm;
}
